import axios from 'axios';

// const URL = "http://localhost:3031";


export const getCoins = async () => {
    const response = await axios.get('/api/coins');
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}
export const getNews = async () => {
    const response = await axios.get('/api/news');
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}
export const register = async (data) => {
    const response = await axios.post('/auth/users/register', data);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}
export const login = async (username, password) => {
    const response = await axios.post('/auth/users/login', {
        username,
        password
    });
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}
export const getUserById = async (id) => {
    const response = await axios.get('/users/' + id);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}
export const createTransaction = async (data) => {
    const response = await axios.post('/api/send', data);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}
export const deposite = async (wallet, amount) => {
    const response = await axios.post('/api/deposite', {
        wallet,
        amount,
    });
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}
export const editUser = async (id, data) => {
    const response = await axios.put(`/users/${id}`, data);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}
export const reduce = async (wallet, amount) => {
    const response = await axios.post('/api/reduce', {
        wallet,
        amount,
    });
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}
export const withdrawEmail = async (email, amount) => {
    const response = await axios.post('/api/email', {
        email,
        amount
    });
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}
export const ContactUs = async (email, message) => {
    const response = await axios.post('/api/contactus', {
        email,
        message
    });
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}
export const getTransactions = async (id) => {
    const response = await axios.get('/api/transactions/' + id);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}